<template>
  <div class="home">
    <admin-left-bar/>
    <router-view/>
    <error-modal/>
  </div>
</template>

<script>
  import AdminLeftBar from "@/components/bars/AdminLeftBar";
  import ErrorModal from "@/components/modals/ErrorModal";
  export default {
    name: "Admin",
    components: { ErrorModal, AdminLeftBar }
  }
</script>

<style scoped>

</style>
