<template>
  <v-dialog v-model="dialog"
            persistent
            max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline" v-if="id===null">Tworzenie użytkownika</span>
        <span class="headline" v-else>Edycja użytkownika</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field outlined label="Imię" v-model="firstName"/>
            </v-col>
            <v-col>
              <v-text-field outlined label="Naziwsko" v-model="lastName"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field outlined label="Pesel" v-model="pesel"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field outlined label="Numer telefonu" v-model="cellphone"/>
            </v-col>
            <v-col>
              <v-text-field outlined label="Adres email" v-model="email"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-combobox :items=agencyList
                        v-model="agencyCode"
                        item-text="name"
                        item-value="code"
                        outlined label="Agencja"/>
            </v-col>
            <v-col>
              <v-text-field outlined label="Numer sprzedawcy" v-model="code"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="closeDialog">
          Anuluj
        </v-btn>
        <v-btn color="primary"
               @click="save">
          Zapisz
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import * as mutations from "@/store/mutations/mutation-types";
  import * as actions from "@/store/actions/action-types";
  import { mapActions, mapMutations, mapState } from "vuex";

  export default {
    name: "UserEdit",
    computed: {
      firstName: {
        get() {
          return this.$store.state.newUser.firstName;
        },
        set(val) {
          this.$store.state.newUser.firstName = val;
        }
      },
      lastName: {
        get() {
          return this.$store.state.newUser.lastName;
        },
        set(val) {
          this.$store.state.newUser.lastName = val;
        }
      },
      email: {
        get() {
          return this.$store.state.newUser.email;
        },
        set(val) {
          this.$store.state.newUser.email = val;
        }
      },
      cellphone: {
        get() {
          return this.$store.state.newUser.cellphone;
        },
        set(val) {
          this.$store.state.newUser.cellphone = val;
        }
      },
      pesel: {
        get() {
          return this.$store.state.newUser.pesel;
        },
        set(val) {
          this.$store.state.newUser.pesel = val;
        }
      },
      code: {
        get() {
          return this.$store.state.newUser.salesmanId;
        },
        set(val) {
          this.$store.state.newUser.salesmanId = val;
        }
      },
      agencyCode: {
        get() {
          return this.$store.state.newUser.agencyId;
        },
        set(val) {
          this.$store.state.newUser.agencyId = val;
        }
      },
      ...mapState({
        agencyList: state => state.agencyList,
        id: state => state.newUser.id,
        dialog: state => state.showCreateUserModal
      })
    },
    methods: {
      ...mapMutations({
        closeDialog: mutations.HIDE_CREATE_USER_MODAL
      }),
      ...mapActions({
        save: actions.CREATE_UPDATE_USER
      })
    },
  }
</script>

<style scoped>

</style>
