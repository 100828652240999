<template>
  <v-card>
    <v-card-title>
      <v-spacer/>
      <v-text-field v-model="search"
                    append-icon="mdi-magnify"
                    label="Szukaj"
                    single-line
                    hide-details/>
    </v-card-title>
    <v-data-table :headers="headers"
                  :items="activityReport"
                  no-data-text="Brak agencji"
                  no-results-text="Brak agencji spełniających kryteria"
                  :loading="agencyLoading"
                  :search="search"
                  :footer-props="footerProps">
    </v-data-table>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import * as actions from '@/store/actions/action-types'

  export default {
    name: "AgencyActivityList",
    created() {
      this.fetchActivity();
    },
    methods: {
      ...mapActions({
        fetchActivity: actions.FETCH_AGENCY_ACTIVITY_REPORT,
      }),
      getJDGColor(status) {
        if (status) {
          return "green"
        } else {
          return "red"
        }
      },
      getJDGLabel(status) {
        if (status) {
          return "Tak"
        } else {
          return "Nie"
        }
      },
    },
    computed: mapState({
      activityReport: state => state.agencyActivityReport,
      agencyLoading: state => state.loading.show,
    }),
    data() {
      return {
        footerProps: {
          "items-per-page-all-text": "Wszystkie",
          "items-per-page-text": "Agencji na stronie",
          "items-per-page-options": [
            10, 20, 50, -1
          ]
        },
        search: '',
        headers: [
          { text: 'Nazwa', value: 'agencyName' },
          { text: 'Kod', value: 'agencyCode' },
          { text: 'Sprzedaż total', value: 'sold' },
          { text: 'Sprzedaż 30 dni', value: 'sold30' },
        ],
      }
    },
  }
</script>

<style scoped>
  .icon-link {
    text-decoration: none;
  }
</style>
