<template>
  <v-navigation-drawer permanent app width="230">
    <img src="@/assets/hiPRO_logo.png"
         alt="Hipro Logo"
         width="122"
         style="vertical-align: middle;"
         class="mr-1 ma-10">
    <v-list nav v-model="currentRoute" class="ml-5" flat>
      <v-list-item v-for="item in navigation"
                   :key="item.title"
                   :to="item.route"
                   color="primary"
                   link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>


    <template v-slot:append>
      <div class="ma-10">
      <v-btn color="primary" x-large @click="logout">Wyloguj</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import * as actions from "@/store/actions/action-types";

  export default {
    name: "AdminLeftBar",
    data() {
      return {
        navigation: [
          { title: 'Użytkownicy', icon: 'mdi-account-circle', route: '/admin/' },
          { title: 'Agencje', icon: 'mdi-account-box-outline', route: '/admin/agencje' },
          { title: 'Aktywność', icon: 'mdi-clock-outline', route: '/admin/activity' }
        ]
      }
    },
    computed: {
      currentRoute() {
        return this.$route.path
      },
    },
    methods: {
      ...mapActions({
        logout: actions.LOGOUT
      }),
      applications() {
        this.$router.push("/").catch()
      },
    }
  }
</script>

<style scoped>
  .support {
    margin-bottom: 40px !important;
  }

  .agent-name {
    position: relative;
    top: 2px;
  }

  .no-style-link {
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none;
  }

</style>
