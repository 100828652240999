var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.agencies,"no-data-text":"Brak agencji","no-results-text":"Brak agencji spełniających kryteria","loading":_vm.agencyLoading,"search":_vm.search,"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"item.businessAvailable",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getJDGColor(item.businessAvailable),"dark":""}},[_vm._v(" "+_vm._s(_vm.getJDGLabel(item.businessAvailable))+" ")])]}},{key:"item.emailRequired",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getJDGColor(item.emailRequired),"dark":""}},[_vm._v(" "+_vm._s(_vm.getJDGLabel(item.emailRequired))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editAgency(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edytuj")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }