<template>
  <v-dialog v-model="dialog"
            persistent
            max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline" v-if="id===null">Tworzenie agencji</span>
        <span class="headline" v-else>Edycja agencji</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field outlined label="Nazwa" v-model="name"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field outlined label="Kod" v-model="code"/>
            </v-col>
            <v-col>
              <v-text-field outlined label="Kod produktu" v-model="productCode"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field outlined label="Bazowy adres" v-model="baseUrl"/>
            </v-col>
            <v-col cols="3">
              <v-checkbox outlined label="JDG" v-model="isBusinessAvailable"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="closeDialog">
          Anuluj
        </v-btn>
        <v-btn color="primary"
               @click="save">
          Zapisz
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import * as mutations from "@/store/mutations/mutation-types";
  import * as actions from "@/store/actions/action-types";
  import { mapActions, mapMutations, mapState } from "vuex";

  export default {
    name: "AgencyEdit",
    computed: {
      name: {
        get() {
          return this.$store.state.newAgency.name;
        },
        set(val) {
          this.$store.state.newAgency.name = val;
        }
      },
      code: {
        get() {
          return this.$store.state.newAgency.code;
        },
        set(val) {
          this.$store.state.newAgency.code = val;
        }
      },
      productCode: {
        get() {
          return this.$store.state.newAgency.productCode;
        },
        set(val) {
          this.$store.state.newAgency.productCode = val;
        }
      },
      baseUrl: {
        get() {
          return this.$store.state.newAgency.baseUrl;
        },
        set(val) {
          this.$store.state.newAgency.baseUrl = val;
        }
      },
      isBusinessAvailable: {
        get() {
          return this.$store.state.newAgency.businessAvailable;
        },
        set(val) {
          this.$store.state.newAgency.businessAvailable = val;
        }
      },
      ...mapState({
        agencyList: state => state.agencyList,
        id: state => state.newAgency.id,
        dialog: state => state.showCreateAgencyModal
      })
    },
    methods: {
      ...mapMutations({
        closeDialog: mutations.HIDE_CREATE_AGENCY_MODAL
      }),
      ...mapActions({
        save: actions.CREATE_UPDATE_AGENCY,
      })
    },
  }
</script>

<style scoped>

</style>
