<template>
  <v-card>
    <v-card-title>
      <v-spacer/>
      <v-text-field v-model="search"
                    append-icon="mdi-magnify"
                    label="Szukaj"
                    single-line
                    hide-details/>
    </v-card-title>
    <v-data-table :headers="headers"
                  :items="agencies"
                  no-data-text="Brak agencji"
                  no-results-text="Brak agencji spełniających kryteria"
                  :loading="agencyLoading"
                  :search="search"
                  :footer-props="footerProps">
      <template v-slot:item.businessAvailable="{ item }">
        <v-chip :color="getJDGColor(item.businessAvailable)"
                dark>
          {{ getJDGLabel(item.businessAvailable) }}
        </v-chip>
      </template>
      <template v-slot:item.emailRequired="{ item }">
        <v-chip :color="getJDGColor(item.emailRequired)"
                dark>
          {{ getJDGLabel(item.emailRequired) }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2"
                    @click="editAgency(item.id)"
                    v-on="on"
                    v-bind="attrs">
              mdi-pencil
            </v-icon>
          </template>
          <span>Edytuj</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import * as actions from '@/store/actions/action-types'

  export default {
    name: "AgencyList",
    created() {
      this.fetchAgencies();
    },
    methods: {
      ...mapActions({
        fetchAgencies: actions.FETCH_AGENCIES,
        editAgency: actions.EDIT_AGENCY
      }),
      getJDGColor(status) {
        if (status) {
          return "green"
        } else {
          return "red"
        }
      },
      getJDGLabel(status) {
        if (status) {
          return "Tak"
        } else {
          return "Nie"
        }
      },
    },
    computed: mapState({
      agencies: state => state.agencyList,
      agencyLoading: state => state.loading.show,
    }),
    data() {
      return {
        footerProps: {
          "items-per-page-all-text": "Wszystkie",
          "items-per-page-text": "Agencji na stronie",
          "items-per-page-options": [
            10, 20, 50, -1
          ]
        },
        search: '',
        headers: [
          { text: 'Nazwa', value: 'name' },
          { text: 'Kod', value: 'code' },
          { text: 'Kod produktu', value: 'productCode' },
          { text: 'Bazowy adres', value: 'baseUrl' },
          { text: 'JDG', value: 'businessAvailable' },
          { text: 'Email', value: 'emailRequired' },
          { text: 'Akcje', value: 'actions', sortable: false }
        ],
      }
    },
  }
</script>

<style scoped>
  .icon-link {
    text-decoration: none;
  }
</style>
