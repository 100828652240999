var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"custom-filter":_vm.userFilter,"no-data-text":"Brak użytkowników","no-results-text":"Brak użytkowników spełniających kryteria","loading":_vm.usersLoading,"search":_vm.search,"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(_vm.getStatus(item.status))+" ")])]}},{key:"item.activationStatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.activationStatus),"dark":""}},[_vm._v(" "+_vm._s(_vm.getActivationStatus(item.activationStatus))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editUser(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edytuj")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.activationStatus !== 'ACTIVE' && item.status === 'ACTIVE')?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.sendActivationEmail(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-email-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Wyślij link aktywacyjny")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status === 'ACTIVE')?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.toggleUser({id:item.id, status: item.status})}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-thick ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Wyłącz użytkownika")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status === 'INACTIVE')?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.toggleUser({id:item.id, status: item.status})}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-bold ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Włącz użytkownika")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }