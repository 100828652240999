<template>
  <v-container>
    <v-row>
      <v-col class="pa-6">
        <span class="text-h4">
          Aktywność
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs v-model="tab">
          <v-tab>Użytkownicy</v-tab>
          <v-tab>Agencje</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <user-activity-list/>
          </v-tab-item>
          <v-tab-item>
            <agency-activity-list/>
          </v-tab-item>
        </v-tabs-items>
<!--      </v-col>-->
<!--    </v-row>-->
<!--    <v-row>-->
<!--      <v-col>-->
<!--        <v-window v-model="tab">-->
<!--          <v-window-item value="users">-->
<!--            Użytkownicy-->
<!--          </v-window-item>-->
<!--          <v-window-item value="agencies">-->
<!--            Agencje-->
<!--          </v-window-item>-->
<!--        </v-window>-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import UserActivityList from "@/components/UserActivityList";
  import AgencyActivityList from "@/components/AgencyActivityList";
  export default {
    name: "Activity",
    components: { AgencyActivityList, UserActivityList },
    data() {
      return {
        tab: null
      }
    },
    methods: {}
  }
</script>

<style scoped>

</style>
