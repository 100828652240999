<template>
  <v-container>
    <v-row>
      <v-col class="pa-6">
        <span class="text-h4">
          Użytkownicy
        </span>
      </v-col>
      <v-spacer/>
      <v-col class="text-right">
        <v-btn color="primary" x-large @click="createUser">Dodaj użytkownika</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <user-list/>
      </v-col>
    </v-row>
    <user-edit/>
  </v-container>
</template>

<script>
  import UserList from "../../components/UserList";
  import UserEdit from "../../components/modals/UserEdit";
  import * as mutations from "@/store/mutations/mutation-types";
  import { mapMutations } from "vuex";

  export default {
    name: "Users",
    components: { UserEdit, UserList },
    data() {
      return {}
    },
    methods: mapMutations({
      createUser: mutations.SHOW_CREATE_USER_MODAL
    })
  }
</script>

<style scoped>

</style>
