<template>
  <v-card>
    <v-card-title>
      <v-spacer/>
      <v-text-field v-model="search"
                    append-icon="mdi-magnify"
                    label="Szukaj"
                    single-line
                    hide-details/>
    </v-card-title>
    <v-data-table :headers="headers"
                  :items="users"
                  :custom-filter="userFilter"
                  no-data-text="Brak użytkowników"
                  no-results-text="Brak użytkowników spełniających kryteria"
                  :loading="usersLoading"
                  :search="search"
                  :footer-props="footerProps">
      <template v-slot:item.status="{ item }">
        <v-chip :color="getColor(item.status)"
                dark>
          {{ getStatus(item.status) }}
        </v-chip>
      </template>
      <template v-slot:item.activationStatus="{ item }">
        <v-chip :color="getColor(item.activationStatus)"
                dark>
          {{ getActivationStatus(item.activationStatus) }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2"
                    @click="editUser(item.id)"
                    v-on="on"
                    v-bind="attrs">
              mdi-pencil
            </v-icon>
          </template>
          <span>Edytuj</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2"
                    @click="sendActivationEmail(item.id)"
                    v-on="on"
                    v-bind="attrs"
                    v-if="item.activationStatus !== 'ACTIVE' && item.status === 'ACTIVE'">
              mdi-email-outline
            </v-icon>
          </template>
          <span>Wyślij link aktywacyjny</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2"
                    @click="toggleUser({id:item.id, status: item.status})"
                    v-on="on"
                    v-bind="attrs"
                    v-if="item.status === 'ACTIVE'"
                    color="red">
              mdi-close-thick
            </v-icon>
          </template>
          <span>Wyłącz użytkownika</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2"
                    @click="toggleUser({id:item.id, status: item.status})"
                    v-on="on"
                    v-bind="attrs"
                    v-if="item.status === 'INACTIVE'"
                    color="green">
              mdi-check-bold
            </v-icon>
          </template>
          <span>Włącz użytkownika</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import * as actions from '@/store/actions/action-types'

  export default {
    name: "UserList",
    created() {
      this.fetchAgencies();
      this.fetchUsers();
    },
    methods: {
      ...mapActions({
        sendActivationEmail: actions.SEND_ACTIVATION_EMAIL,
        toggleUser: actions.TOGGLE_USER,
        fetchAgencies: actions.FETCH_AGENCIES,
        fetchUsers: actions.FETCH_USERS,
        editUser: actions.EDIT_USER
      }),
      getColor(status) {
        switch (status) {
          case "INACTIVE":
            return "red";
          case "AWAITING_REGISTRATION":
            return "blue";
          case "ACTIVE":
            return "green";
        }
      },
      getStatus(status) {
        switch (status) {
          case "INACTIVE":
            return "Wyłączony";
          case "ACTIVE":
            return "Włączony";
        }
      },
      getActivationStatus(status) {
        switch (status) {
          case "INACTIVE":
            return "Nie aktywowany";
          case "AWAITING_REGISTRATION":
            return "W trakcie";
          case "ACTIVE":
            return "Aktywny";
        }
      },
      userFilter(value, search, item) {
        return value != null &&
          search != null &&
          typeof value !== 'boolean' && (
            this.getActivationStatus(item.activationStatus).toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
            this.getStatus(item.status).toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
            value.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
          )
      }
    },
    computed: mapState({
      users: state => state.users,
      usersLoading: state => state.loading.show,
    }),
    data() {
      return {
        footerProps: {
          "items-per-page-all-text": "Wszyscy",
          "items-per-page-text": "Użytkowników na stronie",
          "items-per-page-options": [
            10,20,50,-1
          ]
        },
        search: '',
        headers: [
          { text: 'Nazwisko', value: 'lastName' },
          { text: 'Imię', value: 'firstName' },
          { text: 'Pesel', value: 'pesel' },
          { text: 'Nr telefonu', value: 'cellphone' },
          { text: 'Email', value: 'email' },
          { text: 'Agencja', value: 'agencyId' },
          { text: 'Numer', value: 'salesmanId' },
          { text: 'Status', value: 'status' },
          { text: 'Status aktywacji', value: 'activationStatus' },
          { text: 'Akcje', value: 'actions', sortable: false }
        ],
      }
    },
  }
</script>

<style scoped>
  .icon-link {
    text-decoration: none;
  }
</style>
