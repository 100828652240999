<template>
<v-container>
    <v-row>
      <v-col class="pa-6">
        <span class="text-h4">
          Agencje
        </span>
      </v-col>
      <v-spacer/>
      <v-col class="text-right">
        <v-btn color="primary" x-large @click="createAgency">Dodaj agencję</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <agency-list/>
      </v-col>
    </v-row>
    <agency-edit/>
  </v-container>
</template>

<script>
  import AgencyList from "../../components/AgencyList";
  import AgencyEdit from "../../components/modals/AgencyEdit";
  import * as mutations from "@/store/mutations/mutation-types";
  import { mapMutations } from "vuex";

  export default {
    name: "Agencies",
    components: { AgencyEdit, AgencyList },
    data() {
      return {}
    },
    methods: mapMutations({
      createAgency: mutations.SHOW_CREATE_AGENCY_MODAL
    })
  }
</script>

<style scoped>

</style>
