<template>
  <v-card>
    <v-card-title>
      <v-spacer/>
      <v-text-field v-model="search"
                    append-icon="mdi-magnify"
                    label="Szukaj"
                    single-line
                    hide-details/>
    </v-card-title>
    <v-data-table :headers="headers"
                  :items="activityReport"
                  :custom-filter="userFilter"
                  no-data-text="Brak użytkowników"
                  no-results-text="Brak użytkowników spełniających kryteria"
                  :loading="usersLoading"
                  :search="search"
                  :footer-props="footerProps">
      <template v-slot:item.status="{ item }">
        <v-chip :color="getColor(item.status)"
                dark>
          {{ getStatus(item.status) }}
        </v-chip>
      </template>
      <template v-slot:item.activationStatus="{ item }">
        <v-chip :color="getColor(item.activationStatus)"
                dark>
          {{ getActivationStatus(item.activationStatus) }}
        </v-chip>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-btn color="primary" @click="downloadActivity">Pobierz aktywność użytkowników</v-btn>
      <form :action="'/api/admin/downloadActivityReport'" style="visibility: hidden" method="POST" name="documentForm"
            target="_blank"/>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import * as actions from '@/store/actions/action-types'

  export default {
    name: "UserActivityList",
    created() {
      this.fetchActivity();
    },
    methods: {
      ...mapActions({
        fetchActivity: actions.FETCH_ACTIVITY_REPORT,
      }),
      downloadActivity() {
        document.documentForm.submit();
      },
      getColor(status) {
        switch (status) {
          case "INACTIVE":
            return "red";
          case "AWAITING_REGISTRATION":
            return "blue";
          case "ACTIVE":
            return "green";
        }
      },
      getStatus(status) {
        switch (status) {
          case "INACTIVE":
            return "Wyłączony";
          case "ACTIVE":
            return "Włączony";
        }
      },
      getActivationStatus(status) {
        switch (status) {
          case "INACTIVE":
            return "Nie aktywowany";
          case "AWAITING_REGISTRATION":
            return "W trakcie";
          case "ACTIVE":
            return "Aktywny";
        }
      },
      userFilter(value, search, item) {
        return value != null &&
          search != null &&
          item.status !== "INACTIVE" &&
          typeof value !== 'boolean' && (
            this.getActivationStatus(item.activationStatus).toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
            value.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
          )
      }
    },
    computed: mapState({
      activityReport: state => state.activityReport,
      usersLoading: state => state.loading.show,
    }),
    data() {
      return {
        footerProps: {
          "items-per-page-all-text": "Wszyscy",
          "items-per-page-text": "Użytkowników na stronie",
          "items-per-page-options": [
            10,20,50,-1
          ]
        },
        search: '',
        headers: [
          { text: 'Nazwisko', value: 'lastName' },
          { text: 'Imię', value: 'firstName' },
          // { text: 'Pesel', value: 'pesel' },
          { text: 'Nr telefonu', value: 'cellphone' },
          { text: 'Email', value: 'email' },
          { text: 'Agencja', value: 'agencyName' },
          // { text: 'Nr. Agencji', value: 'agencyCode' },
          // { text: 'Numer', value: 'salesmanCode' },
          // { text: 'Status', value: 'status' },
          { text: 'Status aktywacji', value: 'activationStatus' },
          { text: 'Ostatnie logowanie', value: 'lastLogin' },
          { text: 'Sprzedaż total', value: 'sold' },
          { text: 'Sprzedaż 30 dni', value: 'sold30' },
        ],
      }
    },
  }
</script>

<style scoped>
  .icon-link {
    text-decoration: none;
  }
</style>
